import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import tabStore from '../stores/TabStore';
import getImgUrl from "./shared/getImgUrl";
import {useNotification} from "./shared/notify";

const PopupLocalization = observer(() => {
    let tab = tabStore.tabs[tabStore.localizationTabIndex];
    let cat = tabStore.tabs[tabStore.localizationTabIndex]?.categories[tabStore.localizationCatIndex];

    const [jsonText, setJsonText] = useState(JSON.stringify(cat ? cat.localization : tab.localization, null, 2));

    const setJsonTxt = (txt) => {
        if(typeof txt === "object")
            setJsonText(JSON.stringify(txt, null, 2))
        else
            setJsonText(txt)
    }
    const showNotification = useNotification;
    //const isHidden = tabStore.localizationTabIndex === null && tabStore.localizationCatIndex === null;
    //if (isHidden) return null;

    let name = cat ? cat.name : tab.name;



/*    const TabContent = () => {
        //useEffect(() => { { setJsonTxt(tab.localization); } });
        return (
            <div>
                <textarea
                    style={{ width: '100%', height: '300px', overflow: 'auto',fontFamily: 'monospace'  }}
                    wrap="off"
                    className=""
                    spellCheck={false}
                    autoCorrect="off"
                    autoCapitalize="off"
                    /!*defaultValue={tab.localization || '{}'}*!/
                    value={jsonText}
                    onChange={(e) => setJsonTxt(e.target.value)}
                />
            </div>
        )
    }

    const CatContent = () => {
        return (
            <span>localizationCatId {tabStore.localizationCatIndex}</span>
        )
    }*/

    const aiGenerate = async () => {
        if (name) {
            try {
                const res = await tabStore.localizeNameAi(name);
                setJsonTxt(res)
                showNotification('Success', 'success');
            } catch (error) {
                console.error('Error aiGenerate:', error);
                showNotification('An error occurred while getting response.', 'error');
            }
        }
    };

    const save = async () => {
        if (cat) {
            //cat
            try {
                const res = await tabStore.editCatLocalization(tabStore.localizationTabIndex, tabStore.localizationCatIndex, JSON.parse(jsonText));
                setJsonTxt(res.localization)
                showNotification('Success', 'success');
            } catch (error) {
                console.error('Error aiGenerate:', error);
                showNotification('An error occurred while saving Category data.', 'error');
            }
        }else{
            //tab
            try {
                const res = await tabStore.editTabLocalization(tabStore.localizationTabIndex, JSON.parse(jsonText));
                setJsonTxt(res.localization)
                showNotification('Success', 'success');
            } catch (error) {
                console.error('Error aiGenerate:', error);
                showNotification('An error occurred while saving Tab data.', 'error');
            }
        }
    };

    return (
        <span className={"popup-block-background"} >
            <div id="popup-block-localization"
                 className="popup-block localization"
                 key={'popup-block-localization'}
                /*style={{display: isHidden ? 'none' : 'flex'}}>*/
                 style={{display: 'flex'}}>
                <span>{name}<span className={"language-button"}><i className='fas fa-language'></i></span></span>
                <span className="close-icon"
                      onClick={() => { tabStore.setLocalizationPopup() }}>&times;</span>
                <div style={{width: '100%'}}>
                    {/*{cat ? <CatContent/> : <TabContent/>}*/}
                    <div>
                        <textarea
                            style={{ width: '100%', height: '300px', overflow: 'auto',fontFamily: 'monospace'  }}
                            wrap="off"
                            className=""
                            spellCheck={false}
                            autoCorrect="off"
                            autoCapitalize="off"
                            /*defaultValue={tab.localization || '{}'}*/
                            value={jsonText}
                            onChange={(e) => setJsonTxt(e.target.value)}
                        />
                    </div>
                </div>
                <div style={{display: "flex", width: "100%", justifyContent: "flex-end"}}>
                    <button className={"edit-button"} onClick={aiGenerate}>Generate AI</button>
                    <button className={"save-button"} onClick={save}>Save</button>
                </div>
            </div>
        </span>

    );
});

export default PopupLocalization;
