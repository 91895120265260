import React, {useEffect, useRef, useState} from 'react';
import {observer} from 'mobx-react-lite';
import tabStore from '../stores/TabStore'; // Предполагается, что у вас есть хранилище MobX для табов
import CategoryList from './Category_0_List';
import {useNotification} from "./shared/notify";
import getImgUrl from "./shared/getImgUrl";
import isIssetStrInLocalization from "./shared/isIssetStrInLocalization";

const TabList = observer(() => {
    const [isCompactMode, setIsCompactMode] = useState(false);
    useEffect(() => {
        tabStore.fetchTabs();
    }, []);

    const showNotification = useNotification;
    let inputRef = useRef(null);

    if (tabStore.loading) {
        return <p>Loading...</p>;
    }
    if (tabStore.error) {
        return <p>Error fetching tabs: {tabStore.error.message}</p>;
    }

    const handleAddTab = async () => {
        const name = prompt("Enter tab name:");
        if (name) {
            const sortParameter = tabStore.tabs.length + 1;
            try {
                await tabStore.addTab(name, sortParameter);
                showNotification('Tab created successfully', 'success');
            } catch (error) {
                console.error('Error creating tab:', error);
                showNotification('An error occurred while creating the tab. Please try again.', 'error');
            }
        }
    };

    const handleEditTab = async (tabIndex) => {
        const tab = tabStore.tabs[tabIndex];
        const newName = prompt("Enter new tab name:", tab.name);
        if (newName && newName !== tab.name) {
            try {
                await tabStore.editTab(tabIndex, {name: newName});
                showNotification('Tab updated successfully', 'success');
            } catch (error) {
                console.error('Error updating tab:', error);
                showNotification('An error occurred while updating the tab. Please try again.', 'error');
            }
        }
    };

    const handleDeleteTab = async (tabIndex) => {
        const tab = tabStore.tabs[tabIndex];
        if (confirm(`Are you sure you want to delete the tab "${tab.name}"?`)) {
            try {
                await tabStore.deleteTab(tabIndex);
                showNotification('Tab deleted successfully', 'success');
            } catch (error) {
                console.error('Error deleting tab:', error);
                showNotification('An error occurred while deleting the tab. Please try again.', 'error');
            }
        }
    };

    const handleAddCategory = async (tabIndex) => {
        const categoryName = prompt("Enter category name:");
        if (categoryName) {
            let categoryDescription = prompt("Enter category description:");
            if (!categoryDescription) categoryDescription = '-'
            try {
                await tabStore.addCategory(tabIndex, categoryName, categoryDescription);
                showNotification('Category created successfully', 'success');
            } catch (error) {
                console.error('Error creating category:', error);
                showNotification('An error occurred while creating the category. Please try again.', 'error');
            }
        }
    };
    const openFileDialog = (i, fileType) => {
        inputRef.current.dataset.fileType = fileType;
        inputRef.current.dataset.index = i;
        inputRef.current.click();
    }
    const updateTabImg = async (event) => {
        const fileType = inputRef.current.dataset.fileType;
        const tabIndex = inputRef.current.dataset.index;
        const imgFile = event.target.files[0];

        const updates = {};
        updates[fileType] = imgFile;

        if (imgFile) {
            try {
                await tabStore.editTab(tabIndex, updates);
                showNotification('Tab updated successfully', 'success');
            } catch (error) {
                console.error('Error updating tab:', error);
                showNotification('An error occurred while updating the tab. Please try again.', 'error');
            }
        }
    };

    const handleDragStart = (e, tabIndex) => {
        e.dataTransfer.setData('tabIndex', tabIndex.toString());
        // Найти родительский элемент li и добавить класс dragging
        const tabElement = e.target.closest('.tab-item');
        tabElement.classList.add('dragging');
        // Скрыть все содержимое табов
        document.querySelectorAll('.category-list').forEach(list => {
            list.style.display = 'none';
        });
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDragEnd = (e) => {
        // Показать все содержимое табов обратно
        document.querySelectorAll('.category-list').forEach(list => {
            list.style.display = 'block';
        });
        // Удалить класс dragging
        document.querySelector('.dragging')?.classList.remove('dragging');
    };

    const handleDrop = async (e, targetIndex) => {
        e.preventDefault();
        const dragType = e.dataTransfer.getData('type');
        if (dragType === 'category') return; // Ignore category drops

        const sourceIndex = parseInt(e.dataTransfer.getData('tabIndex'));
        if (sourceIndex === targetIndex) return;

        try {
            const sourceSortParameter = tabStore.tabs[sourceIndex].sortParameter;
            const targetSortParameter = tabStore.tabs[targetIndex].sortParameter;

            await tabStore.editTab(sourceIndex, {sortParameter: targetSortParameter});
            await tabStore.editTab(targetIndex, {sortParameter: sourceSortParameter});
            await tabStore.fetchTabs(); // Обновляем список после сортировки
            showNotification('Tabs reordered successfully', 'success');
        } catch (error) {
            console.error('Error reordering tabs:', error);
            showNotification('An error occurred while reordering tabs', 'error');
        }
    };

    return (
        <div className="tab-structure">
            <div className="mode-toggle">
                <label className="switch">
                    <input
                        type="checkbox"
                        checked={isCompactMode}
                        onChange={(e) => setIsCompactMode(e.target.checked)}
                    />
                    <span className="slider"></span>
                </label>
                <span className="mode-label">Compact Mode</span>
            </div>
            <ul className="tab-list">
                <input type="file" ref={inputRef} onChange={updateTabImg} style={{display: 'none'}}/>
                {tabStore.tabs.length === 0 ? (
                    <p>No tabs available. Click "Add New Tab" to create one.</p>
                ) : (
                    tabStore.tabs.map((tab, tabIndex) => (
                        <li key={tabIndex}
                            className="nested-item tab-item"
                            onDragOver={(e) => handleDragOver(e)}
                            onDrop={(e) => handleDrop(e, tabIndex)}
                            onDragEnd={handleDragEnd}
                        >
                            <div className="tab-header" data-id={`tab-${tabIndex}`}
                                 style={{display: 'flex', alignItems: 'center', gap: '10px'}}>

                                <span style={{display: "flex"}}>
                                    <div className="drag-handle"
                                         draggable="true"
                                         onDragStart={(e) => handleDragStart(e, tabIndex)}>
                                        <i className="fas fa-grip-vertical"></i>
                                    </div>
                                    <div className={"tab-thumbnail-container"}>
                                        <img src={getImgUrl(tab.imgUrl)} alt={tab.name} className="tab-thumbnail"/>
                                        <button className="edit-tab-img-btn" data-tab={tabIndex} title="Edit Image"
                                                onClick={() => openFileDialog(tabIndex, 'imageFile')}>
                                            <i className="fas fa-upload"></i>
                                        </button>
                                    </div>
                                    <span className={"tab-thumbnail-container-name"}>
                                        <div className={"cat-thumbnail-container"}>
                                            <img src={getImgUrl(tab.icoUrl)} className="cat-thumbnail"/>
                                            <button className="edit-cat-img-btn" data-tab={tabIndex} title="Edit Image"
                                                    onClick={() => openFileDialog(tabIndex, 'icoFile')}>
                                                <i className="fas fa-upload"></i>
                                            </button>
                                        </div>
                                    <span className="tab-name">
                                        {tab.name}
                                        <span
                                            className={"language-button"}
                                            onClick={()=>tabStore.setLocalizationPopup(tabIndex)}
                                        ><i className='fas fa-language' style={{color: !isIssetStrInLocalization(tab.localization, tab.name)? 'red':''}}></i></span>
                                    </span>
                                    <span> &nbsp; {tab.countSounds} sounds</span>
                                </span>
                                </span>


                                <span>
                                  <button className="edit-tab-btn" data-tab={tabIndex} title="Edit Tab"
                                          onClick={() => handleEditTab(tabIndex)}>
                                    <i className="fas fa-pencil-alt"></i>
                                  </button>
                                  <button className="delete-tab-btn" data-tab={tabIndex} title="Delete Tab"
                                          onClick={() => handleDeleteTab(tabIndex)}>
                                    <i className="fas fa-trash-alt"></i>
                                  </button>
                                  <button className="add-category-btn" data-tab={tabIndex}
                                          onClick={() => handleAddCategory(tabIndex)}>
                                    <i className="fas fa-plus"></i> Add Category
                                  </button>
                                </span>
                            </div>
                            {!isCompactMode && <CategoryList categories={tab.categories} tabIndex={tabIndex}
                                                             mainCategoryIndex={tab.mainCategoryId}/>}
                        </li>
                    ))
                )}
            </ul>
            <button className="add-tab-btn" onClick={handleAddTab}>
                Add New Tab
            </button>
        </div>
    );
});

export default TabList;
