
export default function isIssetStrInLocalization(obj, name) {
    for (const lang in obj) {
        if (obj.hasOwnProperty(lang)) {
            if (obj[lang].name === name) {
                return true;
            }
        }
    }
    return false;
}
