import './styles/App.css';
import React, {useContext} from 'react';
import TabList from './components/Tab_0_List';
import { AuthProvider, AuthContext } from './context/AuthContext';
import LoginForm from './components/login/LoginForm';
import PopupImportImgBlock from "./components/PopupImportImgBlock";
import PopupLocalization from "./components/PopupLocalization";
import tabStore from "./stores/TabStore";
import {observer} from "mobx-react-lite";
const App = observer(() => {
    const { isAuthenticated, logout } = useContext(AuthContext)
    const isHiddenPopupLocalization = tabStore.localizationTabIndex === null && tabStore.localizationCatIndex === null;
    return (
                <div>
                    <header>
                        <h1>Relaxia Admin Panel</h1>
                        {isAuthenticated ? <button className={'delete-category-btn'} onClick={logout}>Logout</button> : null}
                    </header>
                    <main>
                        <section id="nested-structure">
                            <h2>Audio Structure</h2>
                            {isAuthenticated ? <TabList /> : <LoginForm />}
                        </section>
                        <PopupImportImgBlock/>
                        { !isHiddenPopupLocalization ? <PopupLocalization/> : null}
                    </main>
                </div>
    );
});
export default App;
