import React, {useCallback, useRef, useState} from 'react';
import { observer } from 'mobx-react-lite';
import getImgUrl from './shared/getImgUrl';
import AudioPlayer from './shared/AudioPlayer';
import tabStore from '../stores/TabStore';
import getAudioUrl from "./shared/getAudioUrl";
import {useNotification} from "./shared/notify";
import {debounce} from "lodash";

const SoundList = observer(({ sounds, tabIndex, categoryIndex }) => {
    const showNotification = useNotification;
    let inputRef = useRef(null);
    const [showPlayer, setShowPlayer] = useState({});
    const [draggingSoundId, setDraggingSoundId] = useState(null);

    const togglePlayer = (soundId) => {
        setShowPlayer((prevState) => ({
            ...prevState,
            [soundId]: !prevState[soundId]
        }));
    };

    const handleDeleteSound = async (soundIndex) => {
        if (confirm(`Are you sure you want to delete the sound "${sounds[soundIndex].name}"?`)) {
            try {
                await tabStore.deleteSound(tabIndex, categoryIndex, soundIndex);
                showNotification('Sound deleted successfully', 'success');
            } catch (error) {
                console.error('Error deleting sound:', error);
                showNotification('An error occurred while deleting the sound. Please try again.', 'error');
            }
        }
    };

    const openFileDialog = (i) => {
        inputRef.current.dataset.index = i;
        inputRef.current.click();
    }
    const updateSoundImg = async (event) => {
        const soundIndex = inputRef.current.dataset.index;
        const imgFile = event.target.files[0];
        if (imgFile) {
            try {
                await tabStore.updateSound(tabIndex, categoryIndex, soundIndex, {imageFile:imgFile});
                showNotification('Sound image updated successfully', 'success');
            } catch (error) {
                console.error('Error updating sound image:', error);
                showNotification('An error occurred while updating the sound image. Please try again.', 'error');
            }
        }
    };
    const handleUpdateSound = async (soundIndex, dataUpdates) => {
        try {
            await tabStore.updateSound(tabIndex, categoryIndex, soundIndex, dataUpdates);
            showNotification('Sound updated successfully', 'success');
        } catch (error) {
            console.error('Error updating sound:', error);
            showNotification('An error occurred while updating the sound. Please try again.', 'error');
        }
    };
    // Используем debounce для уменьшения числа API вызовов
    const debouncedEdit = useCallback(
        debounce((soundIndex, data) => {
            handleUpdateSound(soundIndex, data);
        }, 600),
        []
    );
    const handleInputChange = (event, soundIndex) => {
        const { name, value } = event.target;
        debouncedEdit(soundIndex, {[name]:value})
    };

    const handleDragStart = (e, soundIndex) => {
        e.stopPropagation();
        e.dataTransfer.setData('type', 'sound');
        e.dataTransfer.setData('soundIndex', soundIndex.toString());
        const soundElement = e.target.closest('.sound-item');
        soundElement.classList.add('dragging');
        setDraggingSoundId(sounds[soundIndex]._id);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDragEnd = (e) => {
        e.stopPropagation();
        document.querySelector('.dragging')?.classList.remove('dragging');
        setDraggingSoundId(null);
    };

    const handleDrop = async (e, targetIndex) => {
        e.preventDefault();
        e.stopPropagation();

        const dragType = e.dataTransfer.getData('type');
        if (dragType !== 'sound') return;

        const sourceIndex = parseInt(e.dataTransfer.getData('soundIndex'));
        if (sourceIndex === targetIndex) return;

        try {
            const sourceSortParameter = sounds[sourceIndex].sortParameter;
            const targetSortParameter = sounds[targetIndex].sortParameter;

            await Promise.all([
                tabStore.updateSound(tabIndex, categoryIndex, sourceIndex, { sortParameter: targetSortParameter }),
                tabStore.updateSound(tabIndex, categoryIndex, targetIndex, { sortParameter: sourceSortParameter })
            ]);
            await tabStore.fetchTabs();
            showNotification('Sounds reordered successfully', 'success');
        } catch (error) {
            console.error('Error reordering sounds:', error);
            document.querySelector('.dragging')?.classList.remove('dragging');
            showNotification('An error occurred while reordering sounds', 'error');
        }
    };

    const getImgImportBtnClass = (currentImgUrl) =>{
        let className = "import-sound-img-btn";
        if (!!tabStore.imgImportExportUrl && currentImgUrl !== tabStore.imgImportExportUrl)
            className+=" show-import"
        return className;
    };
    const getImgImportContainerClass = (currentImgUrl) =>{
        let className = "sound-thumbnail-container";
        if (!!tabStore.imgImportExportUrl && currentImgUrl !== tabStore.imgImportExportUrl)
            className+=" ready-to-import"
        return className;
    };

    return (
        <ul className="nested-list sound-list">
            <input type="file" ref={inputRef} onChange={updateSoundImg} style={{display: 'none'}}/>
            {sounds.length === 0 ? (
                <p>No sounds in this category. Click "Add Sounds" to create one.</p>
            ) : (
                sounds.map((sound, soundIndex) => {
                    //const isPremium =
                    return (
                    <li key={sound.name+soundIndex+sound?._id} className="nested-item sound-item"
                        draggable
                        onDragStart={(e) => handleDragStart(e, soundIndex)}
                        onDragOver={handleDragOver}
                        onDrop={(e) => handleDrop(e, soundIndex)}>
                        <div className="sound-item-container">
                            <div className={getImgImportContainerClass()}>
                                <img src={getImgUrl(sound.imgUrl)} alt={sound.name} className="sound-thumbnail" />
                                <button className="edit-sound-img-btn" data-tab={tabIndex} data-category={categoryIndex} data-sound={soundIndex} title="Edit Image" onClick={()=>openFileDialog(soundIndex)}>
                                    <i className="fas fa-upload"></i>
                                </button>
                                <button className="export-sound-img-btn" data-tab={tabIndex} data-category={categoryIndex} data-sound={soundIndex} data-current-img={sound.imgUrl} title="Set img to another sound"
                                    onClick={()=>tabStore.setSoundImgExport(sound.imgUrl)}>
                                    <i className="fas fa-eye-dropper"></i>
                                </button>
                                <button className={getImgImportBtnClass(sound.imgUrl)} data-tab={tabIndex} data-category={categoryIndex} data-sound={soundIndex} data-current-img={sound.imgUrl} title="Set img here"
                                    onClick={()=>{handleUpdateSound(soundIndex, {imgUrl:tabStore.imgImportExportUrl})}}>
                                    <i className="fas fa-fill-drip"></i>
                                </button>
                            </div>
                            <div className="sound-info">
                                <span className="sound-info-line">
                                    <button
                                        className="show-player-btn"
                                        onClick={() => togglePlayer(soundIndex)}
                                        title={showPlayer[soundIndex] ? 'Hide Player' : 'Show Player'}>
                                        <i className="far fa-play-circle"></i>
                                    </button>
                                    <input
                                        type="text"
                                        className="sound-name"
                                        name={'name'}
                                        defaultValue={sound.name}
                                        data-tab={tabIndex}
                                        data-category={categoryIndex}
                                        data-sound={soundIndex}
                                        onChange={(e) => handleInputChange(e, soundIndex)}
                                    />
                                    <span>&asymp;{sound.duration}min</span>
                                </span>
                                {sound.status === 'loading' && <span>Loading...</span>}
                                <span>
                                    <button className={sound.isPremium?"premium-btn":"not-premium-btn"}
                                            title={sound.isPremium? "It's PREMIUM Sound":"Set as PREMIUM Sound"}
                                            onClick={() => handleUpdateSound(soundIndex, {isPremium: !sound.isPremium})}>
                                        <i className="far fa-gem"></i>
                                    </button>
                                    <button className="delete-sound-btn" title="Delete Sound"
                                            onClick={() => handleDeleteSound(soundIndex)}>
                                        <i className="fas fa-trash-alt"></i>
                                    </button>
                                </span>
                            </div>
                        </div>
                        <input
                            type="file"
                            className="edit-sound-img-file"
                            data-tab={tabIndex}
                            data-category={categoryIndex}
                            data-sound={soundIndex}
                            style={{ display: 'none' }}
                        />
                        {showPlayer[soundIndex] && (
                            <AudioPlayer soundUrl={getAudioUrl(sound.soundUrl)} />
                        )}
                    </li>
                )})
            )}
        </ul>
    );
});
export default SoundList;
